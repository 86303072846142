span {
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px; /* 214.286% */
}

.title {
  margin-bottom: 10px;
  
}

.icon-color {
  color: #e0e0e0;
}
