h1,
h2,
h4,
li,
p {
  color: white;
}

.home {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  margin: 0px auto;
}

.settings {
  
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 778px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  border-radius: 37px;
  background: rgba(13, 13, 13, 0.80);
  padding: 60px 57px 76px 66px;
  margin: auto;
}

.start-btn {
  padding: 30px;
}

.conversation-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 37px;
  background: rgba(13, 13, 13, 0.80);
  width: 100%;
  max-width: 644px;
  padding-top: 59px;
}

.shared-conversation-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  text-align: center;
}

@keyframes pulse {
  0% {
    transform: scale(1.2);
    opacity: 0.7;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.avatar-wrapper {
  position: relative;
  display: inline-block;
  width: 160px;
  min-height: 159px;
  border-radius: 14px;
  border: 2px solid rgba(173, 173, 173, 0.50);
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarDisplay {
  width: 140px !important;
  height: 112px !important;
}

.pulsating-avatar::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  border-radius: 50%;
  animation: pulse 1s infinite;
  opacity: 0;
  z-index: -1;
}

.text-area {
  display: flex;
  padding: 16px 20px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: large;
}

@media (min-width: 768px) {
  .md-align-self {
    align-self: stretch;
  }
}

.back-button {
  color: #FFF;

  text-align: center;
  /* Text/btn_lg */
  font-family: Oxygen;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 160% */
}

.start-message {
  color: #FFF;
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 24px;
  /* 115.385% */
}