.languages-label {
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 214.286% */
}

.select {
  background-color: #3D3D3D6B;
  color: #ffffff;
  border-color: #ADADAD85;
  width: 100%;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
  margin-bottom: 24px;
}