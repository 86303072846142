label {
  margin-bottom: 8px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 214.286% */

}

.advanced-options {
  display: inline-flex;
  background-color: #3D3D3D6B;
  border: 1.5px solid #ADADAD85;
  border-radius: 6px;
  margin-bottom: 22px;
  flex-direction: column;
  align-items: flex-start;
}

.quivr-text-input {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
}