.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  color: #FFF;
  font-family: 'Prompt', Helvetica;
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 30px;
}

.recording {
  color: firebrick;
  padding-left: 1.2em;
}

.recording::before {
  content: '🔴';
  margin-right: 3px;
  animation: recording 600ms alternate infinite;
}

@keyframes recording {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.2;
  }
}

.actions {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.text-white {
  color: white;
}